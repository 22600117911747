<template>
    <div v-if="chartData.length" id="heatmap"></div>
</template>
<script>
import * as d3 from "d3"

export default {
    props: {
        chartData: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            heatmapDiv: null
        }
    },
    methods: {
        loadHeatmapChart() {
            //Read the data
            // d3.csv("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/heatmap_data.csv").then(function(data) {

            // Labels of row and columns -> unique identifier of the column called 'treatment_area' and 'nurse'
            const treatmentAreas = Array.from(new Set(this.chartData.map(d => d.treatment_area)))
            const nurses = Array.from(new Set(this.chartData.map(d => d.nurse)))

            // Initialize Heatmap
            // set the dimensions and margins of the graph
            const rowHeight = 28;
            const margin = {top: 20, right: 25, bottom: 50, left: 100},
            width = this.heatmapDiv.offsetWidth - margin.left - margin.right,
            height = rowHeight * nurses.length;

            // append the svg object to the body of the page
            const svg = d3.select("#heatmap")
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            // .attr("height", height)
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

            // Build X scales and axis:
            const x = d3.scaleBand()
                .range([ 0, width ])
                .domain(treatmentAreas)
                .paddingInner(0.1)
                .paddingOuter(0.1)
                .align(1)

            // labels
            svg.append("g")
                .style("font-size", 15)
                .attr("transform", `translate(0, ${height})`)
                .attr("class", "ticks")
                .call(d3.axisBottom(x).tickSize(8).tickSizeOuter(0)) 
                // .select(".domain").remove()
                
            svg.selectAll(".tick text")
                .attr("writing-mode", `tb`)
                .attr("transform", `rotate(180)`)
                .attr("y", `0%`)
                .attr("dy", -12)
                .attr("dominant-baseline", `middle`)
                .attr("text-anchor", `end`)
            
            // dynamically update svg height
            const ticksHeight = d3.select('.ticks').node().getBoundingClientRect().height
            d3.select('#heatmap svg').attr("height", height + ticksHeight + margin.bottom)

            // Build Y scales and axis:
            const y = d3.scaleBand()
                .range([ height, 0 ])
                .domain(nurses)
                .padding(0.1);
                
            svg.append("g")
                .style("font-size", 15)
                .call(d3.axisLeft(y).tickSize(0))
                .select(".domain").remove()

            // dynamically update Y tick's padding based on text width
            // const yTicksWidth = d3.select('.ticks + g').node().getBoundingClientRect().width
            // d3.select('#heatmap svg > g').attr("transform", `translate(${yTicksWidth}, ${margin.top})`)
            // console.log('yTicksWidth', yTicksWidth)
            
            // x.range([ 0, this.heatmapDiv.offsetWidth - yTicksWidth])

            // Build color scale
            const myColor = d3.scaleLinear()
                // .interpolator(d3.interpolateInferno)
                .range(["#EAFFFC", "#14d2b8", "#3B635D"])
                .domain([0,50,100])

            // create a tooltip
            const tooltip = d3.select("#heatmap")
                .append("div")
                .style("opacity", 0)
                .attr("class", "tooltip")

            // Three function that change the tooltip when user hover / move / leave a cell
            const mouseover = function(event,d) {
                tooltip
                .style("opacity", 1)
                d3.select(this)
                .style("stroke", "rgba(61,201,179,1)")
                .style("stroke-width", 2)
                .style("opacity", 1)
            }
            const mousemove = function(event,d) {
                tooltip
                .html(
                "<div><span>Nurse:</span> " + d.nurse + "</div>" +
                "<div><span>Treatment Area:</span> " + d.treatment_area + "</div>" +
                "<div><span>Treatment Count:</span> " + d.treatment_count + "</div>" +
                "<div><span>Percentage:</span> " + d.percentage + "%</div>" +
                "<div><span>Total Treatments:</span> " + d.total_treatment_count + "</div>")
                .style("left", (d3.pointer(event)[0]+50) + "px")
                .style("top", (d3.pointer(event)[1]+80) + "px")
            }
            const mouseleave = function(event,d) {
                tooltip
                .style("opacity", 0)
                d3.select(this)
                .style("stroke", "none")
                .style("opacity", 1)
            }

            // add the squares
            svg.selectAll()
                .data(this.chartData, function(d) {return d.treatment_area + ':' + d.nurse;})
                .join("rect")
                .attr("x", function(d) { return  x(d.treatment_area) })
                .attr("y", function(d) { return y(d.nurse) })
                .attr("rx", 4)
                .attr("ry", 4)
                .attr("width", x.bandwidth() )
                .attr("height", y.bandwidth() )
                .style("fill", function(d) { return myColor(d.percentage)} )
                .style("stroke-width", 4)
                .style("stroke", "none")
                .style("opacity", 1)
                .on("mouseover", mouseover)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave)
            // })

            // Add title to graph
            // svg.append("text")
            //         .attr("x", 0)
            //         .attr("y", -50)
            //         .attr("text-anchor", "left")
            //         .style("font-size", "22px")
            //         .text("A d3.js heatmap");

            // Add subtitle to graph
            // svg.append("text")
            //         .attr("x", 0)
            //         .attr("y", -20)
            //         .attr("text-anchor", "left")
            //         .style("font-size", "14px")
            //         .style("fill", "grey")
            //         .style("max-width", 400)
            //         .text("A short description of the take-away message of this chart.");
        },
        checkHeatmapDiv() {
            // remove existing svg and tooltip
            if(!d3.select("#heatmap svg").empty()){
                d3.select("#heatmap svg").remove(); 
            }
            if(!d3.select("#heatmap .tooltip").empty()){
                d3.select("#heatmap .tooltip").remove(); 
            }

            this.heatmapDiv = document.querySelector('#heatmap');

            if(this.heatmapDiv)
                this.loadHeatmapChart()
        },
        initializeHeatmap(){
            this.heatmapDiv = document.querySelector('#heatmap');
            if(this.chartData.length) {
                // this.loadHeatmapChart()
                this.checkHeatmapDiv()
            }
        }
    },
    watch: {
        chartData(val) {
            this.initializeHeatmap()
        }
    },
    mounted() {
        this.initializeHeatmap()
    },
    created() {
        window.addEventListener("resize", this.checkHeatmapDiv);
    },
    destroyed() {
        window.removeEventListener("resize", this.checkHeatmapDiv);
    },
}
</script>
<style>
#heatmap svg text {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
}
#heatmap svg .ticks line,
#heatmap svg .ticks path {
    color: #aaa;
}
#heatmap .tooltip {
    position: absolute;
    background-color: rgba(0,0,0,0.8);
    color: #fff;
    border-radius: 5px;
    padding: 5px;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 12px;
}
#heatmap .tooltip span {
    font-weight: bold;
}
</style>