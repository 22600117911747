<template>
    <div>
        <span class="legend-circle" :style="{backgroundColor: `${color}`, width: `${width}px`, height: `${height}px`}"></span>
        <span class="legend-label">{{ label }}</span>
    </div> 
</template>
<script>
export default {
    props: {
      label: {
        type: String,
        default: ''
      },
      width: {
        type: Number,
        default: 15
      },
      height: {
        type: Number,
        default: 15
      },
      color: {
        type: String,
        default: "#000"
      }
    }
}
</script>
<style>
.legend-circle {
    background-color: blue;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: 3px;
}
</style>