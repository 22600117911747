<template>
    <div :id="chartId" class="venn-div"></div>
</template>
<script>
import * as venn from 'venn.js'
import * as d3 from "d3"

export default {
    props: {
        chartId: {
            type: String,
            default: 'venn'
        },
        chartData: {
            type: Array,
            default: () => []
        },
        showLabel: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        initializeVennDiagram() {
            if(this.chartData.length) {
                const width = 350;
                const height = 200;
                // Initialize Venn Diagram
                d3.select("#"+this.chartId).datum(this.chartData).call(venn.VennDiagram().width(width).height(height));
                const svg = d3.select("#"+this.chartId)
                
                const vennSvg = svg.select('svg')._groups[0][0]
                vennSvg.removeAttribute("height");
                vennSvg.removeAttribute("width");
                vennSvg.setAttribute("viewBox", `0 0 ${width} ${height}`);
                vennSvg.setAttribute("preserveAspectRatio", "xMaxYMin meet");
                vennSvg.setAttribute("class", "svg-content-responsive");

                
                if(this.chartData[0].size){
                    if(this.chartData[0].sets)
                    svg.select(`[data-venn-sets='${this.chartData[0].sets[0]}'].venn-circle text.label`)
                        .attr('dy', -18)
                        .text(function(d) {
                            if(this.showLabel) {
                                return d[0].sets[0] + ": " + d[0].size + "%";
                            }
                            return d[0].size + "%";
                        })
                }
                
                if(this.chartData[1].size){
                    svg.select(`[data-venn-sets='${this.chartData[1].sets[0]}'].venn-circle text.label tspan`)
                        .attr('dy', -18)
                        .text(function(d) {
                            if(this.showLabel) {
                                return d[1].sets[0] + ": " + d[1].size + "%";
                            }
                            return d[1].size + "%";
                        });
                }
                
                if(this.chartData[2].size){
                    svg.select(".venn-intersection text.label tspan")
                        .attr('dy', 18)
                        .text(function(d) {
                            
                            if(this.showLabel) {
                                return "Both: " + d[2].size + "%";
                            }
                            return d[2].size + "%";
                        })
                        .attr("fill", "#000");
                }
            }
        },
        checkVennDiv() {
            // remove existing svg and tooltip
            if(!d3.select(`#${this.chartId} svg`).empty()){
                d3.select(`#${this.chartId} svg`).remove(); 
            }

            this.initializeVennDiagram()
        }
    },
    mounted() {
        this.checkVennDiv()
    },
    watch: {
        chartData(val) {
            this.checkVennDiv()
        }
    },
}
</script>
<style>
.venn-div {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 60%; /* aspect ratio */
    vertical-align: top;
    overflow: hidden;
}
.svg-content-responsive {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 0;
}
.venn-div svg:hover g:not(:hover) path { 
    opacity: .5;
    z-index: 9999;
}
.venn-div svg:hover g:not(:hover) text { 
    opacity: .5; 
}
.venn-div svg:hover g:hover text {
    color: #000;
    font-weight: bold;
}
.venn-circle:nth-child(1) path {
    fill: #4A6FA5;
    fill-opacity: 0.5 !important;
}
.venn-circle:nth-child(2) path {
    fill: #99E5DB;
    fill-opacity: 0.5 !important;
}
.venn-circle .label {
    fill: #000 !important;
}
 </style>