<template>
    <Radar :chartData="chartData" :chart-options="chartOptions" />
</template>
<script>
import { Radar } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

export default {
    components: {
        Radar
    },
    props: {
        chartData: {
            type: Object,
            default: () => []
        },
        chartOptions: {
            type: Object,
            default: () => { }
        }
    }
}
</script>